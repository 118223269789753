import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  inject,
  signal,
} from '@angular/core';
import { FEATURE_TOGGLING } from './feature.toggling.token';
import { Feature } from './feature.enum';
import { isFeatureEnabled } from './feature-toggling.helper';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[mlkFeatureFlag]',
  standalone: true,
  exportAs: 'mlkFeatureFlag',
})
export class FeatureFlagDirective {
  private readonly templateRef = inject(TemplateRef);
  private readonly viewContainer = inject(ViewContainerRef);
  private readonly features = inject(FEATURE_TOGGLING);
  private readonly hasView = signal(false);

  @Input({ required: true }) set mlkFeatureFlag(feature: Feature | string) {
    if (
      isFeatureEnabled(this.features, feature, environment.name) &&
      !this.hasView()
    ) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView.set(true);
    } else {
      this.viewContainer.clear();
      this.hasView.set(false);
    }
  }

  @Input() set featureFlagElse(elseTemplateRef: TemplateRef<unknown>) {
    if (!this.hasView()) {
      this.viewContainer.createEmbeddedView(elseTemplateRef);
    }
  }
}
